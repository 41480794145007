export default {
  Password: { required: true, min: 8, max: 64 },
  OrgName: { required: true, max: 256 },
  OrgDescription: { max: 256 },
  OrgTwilioRegion: { required: true },
  OrgTwilioKey: { required: true },
  OrgTwilioSID: { required: true },
  OrgTwilioSecret: { required: true },
  OrgTwilioAuthToken: { required: true },
  ClientName: { required: true, max: 256 },
  ClientDescription: { max: 256 },
  ClientMaxCapacity: { numeric: true },
  ClientRedirectURL: { url: true },
  ClientReturnURL: { url: true },
  ClientTagline: { max: 128 },
  GameName: { required: true, max: 256 },
  GameExternalName: { max: 256 },
  GameDescription: { max: 256 },
  GameClient: { max: 256 },
  GameClientID: {},
  GamePotato: { numeric: true },
  GameNumberOfPlayers: { numeric: true },
  MissionName: { required: true, max: 256 },
  MissionTitle: { required: true, max: 256 },
  MissionPoints: { numeric: true },
  MissionType: { required: true },
  TwitchAccountName: { required: true, max: 256 },
  TwitchAccountURL: { required: true, url: true },
  TwitchAccountAPIKey: { required: true },
  HostFirstName: { required: true, max: 256 },
  HostLastName: { required: true, max: 256 },
  VideoMissionTimeLimit: { required: false, numeric: true },
  HostRole: { required: true },
  HostOrgID: { required: true },
  HostEmail: { required: true, email: true },
  HostImageURL: { url: true },
  ThemeName: { required: true, max: 256 },
  ThemeTagline: { max: 256 },
  ThemeColor: { required: true, hex: true }
}
